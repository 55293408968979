import jsonp from 'jsonp';

export const loadLocalAddress = async () => {
  return new Promise((resolve, reject) => {
    jsonp('https://api.ipify.org/?format=jsonp', {}, (err, data) => {
      if (err) {
        reject(err)
      }else {
        resolve(data)
      }
    })
  })
}