import request from "../utils/request";
import {userStore} from '../stores'

export const putLoginPhone = async (phone, code) => {
  return request.put('/wechat/login-phone', {requestType: 'form', data: {phone, code}})
}

export const getWechatInfo = async () => {
  if (!userStore.isLogin) {
    throw new BusiError('尚未登录')
  }
  if (userStore.isVirtual) {
    return []
  }
  return request.get(`/user/${userStore.userkey}/wechat`)
}