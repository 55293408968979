
// import qs from 'query-string';

// import {extend} from 'umi-request';
// import upload from 'rc-upload/lib/request';
// import {RESUME_PATH} from "../utils/constants";

// const request = extend({
//   prefix: RESUME_PATH,
// });

// const authHandler = async (ctx, next) => {
//   const {requestType} = ctx.req.options;
//   if (requestType === 'form-data') {
//     ctx.res = await formDataRequest(ctx);
//   } else {
//     if (requestType === 'text') {
//       ctx.req.options.headers = {
//         ...ctx.req.options.headers,
//         "Content-Type": "text/plain;charset=utf-8"
//       }
//     }
//     await next();
//   }
//   const {res} = ctx;
//   if (!res) {
//     throw new ApiError(res.status, res.meta.reason, res.meta.message);
//   }
//   ctx.res = res;
// }

// request.use(authHandler)


// const formDataRequest = async (ctx) => {
//   const {options: {file, method, headers, onProgress}, url} = ctx.req;
//   return new Promise((resolve, reject) => {
//     const options = {
//       filename: 'resume',
//       file: file,
//       method,
//       action: url,
//       headers,
//       timeout: 10 * 60 * 1000,
//       onProgress,
//       onSuccess: (body) => {
//         console.log('body---', body);
//         resolve(body);
//       },
//       onError: async (error, body) => {
//         try {
//           throw new ApiError(res.status, res.meta?.reason || res.reason, res.meta?.message || res.message)
//         } catch (e) {
//           e.body = body;
//           reject(e);
//         }
//       }
//     }
//     upload(options);
//   });
// }


// export const postResumeUpload = async ({promptGroupId, file, onProgress}) => {
//   console.log('------')
//   return request.post(qs.stringifyUrl({
//     url: '/analyze-resume',
//     query: {promptGroupId}
//   }, {skipNull: true, skipEmptyString: true}), {requestType: 'form-data', file, filename: 'resume', onProgress});
// }


import request from "../utils/request";
import {userStore} from '../stores'
import qs from "query-string";

export const postResumeTransform = ({file, onProgress}) => {
  return request.post('/resume/transform', {requestType: 'form-data', file,  onProgress});
}

export const postResumeOptimize = ({resume, jd}) => {
  return request.post('/resume/optimize', {data: {resume, jd}});
}


export const getResumeById = (code) => {
  return request.get(`/resume/${code}`)
}


export const findResumes = async (query) => {
  if (!userStore.isLogin) {
    throw new BusiError('尚未登录')
  }
  if (userStore.isVirtual) {
    return []
  }
  return request.get(qs.stringifyUrl({
    url: `/resume/all`, query
  }, {skipEmptyString: true, skipNull: true}))
}

// export const postResumeUpload = async ({promptGroupId, file, onProgress}) => {
//   console.log('------')
//   return request.post(qs.stringifyUrl({
//     url: '/analyze-resume',
//     query: {promptGroupId}
//   }, {skipNull: true, skipEmptyString: true}), {requestType: 'form-data', file, filename: 'resume', onProgress});
// }