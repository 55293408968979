import {AuthRoute} from "@chaosoft/admin";
import {useStores} from "./stores/hooks";
import {observer} from 'mobx-react';

const LoginRoute = observer((props) => {
  const {userStore} = useStores();
  return (
    <AuthRoute {...props} access={userStore.isLogin} redirect="/login">
      {props.children}
    </AuthRoute>
  )
})

const routes = [{
  path: '/',
  redirect: '/home',
  exact: true
}, {
  path: '/home',
  component: './pages/home/home.js',
  exact: true
}, {
  path: '/resume/:key',
  component: './pages/resume/resume.js',
  exact: true
}, 
{
  path: '/optimize-resume',
  component: './pages/resume/optimize-resume.js',
  exact: true
},
{
  path: '/login',
  component: './pages/login/login.js',
  exact: true
}, {
  path: '/bind',
  component: './pages/login/bind.js',
  exact: true
}, {
  path: '/account',
  component: './pages/account/account.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/vip',
  component: './pages/product/vip.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/pay/:order',
  component: './pages/order/pay.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/orders',
  component: './pages/order/list.js',
  Route: LoginRoute,
  exact: true
}, {
  path: '/orders/:order',
  component: './pages/order/order.js',
  Route: LoginRoute,
  exact: true
}
, {
  path: '/promotion',
  component: './pages/promotion/promotion.js',
  Route: LoginRoute,
  exact: true
}

];

export default routes;
