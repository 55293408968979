import {action, computed, observable} from "mobx";
import {LocalStore} from "./local.store";
import {ResumeApi} from "../api";

class ResumeStore extends LocalStore {

  @observable resume = {}
  @observable file = null;

  get storeName() {
    return "resume_data"
  }

  @computed
  get resumeInfo() {
    return this.datas.resume || '';
  }

  @computed
  get jdInfo() {
    return this.datas.jobDesc || '';
  }

  @computed
  get optimizedInfo() {
    return this.datas.optimizedInfo || {};
  }

  saveFile = (file) => {
    this.file = file;
  }

  // @action 
  // postResumeUpload = async ({promptGroupId, file, callback})  => {
  //   if (file) {
  //     this.file = file;
  //   }
  //   const res = await ResumeApi.postResumeUpload({
  //     promptGroupId,
  //     file: this.file,
  //   });
  //   console.log('----res',  res);

   
  //   this.resume = {
  //     ...this.resume,
  //     [promptGroupId]: Object.values(res)[0],
  //   }

  //   callback && callback();
  // }
}

export default new ResumeStore()