import {action, computed, observable, runInAction} from "mobx";
import {LocalStore} from "./local.store";
import {UserApi, WechatApi} from "../api";
import qs from "query-string";
import {BusiError} from "../utils/common";
import dayjs from "dayjs";

class UserStore extends LocalStore {

  @observable userInfo = {}
  @observable queryToken
  @observable targets = []
  @observable wechat = {}
  @observable channel

  get storeName() {
    return "user_data"
  }

  @computed
  get loginInfo() {
    return this.datas.login || {};
  }

  @computed
  get userkey() {
    const loginkey = this.loginInfo.userKey
    return loginkey && loginkey.split(':')[1];
  }

  @computed
  get token() {
    return this.loginInfo?.token
  }

  @computed
  get isLogin() {
    return !!this.token;
  }

  @computed
  get isVirtual() {
    const {userKey} = this.loginInfo;
    return userKey === 'virtual'
  }

  @computed
  get isMember() {
    const {memberExpiredTime} = this.userInfo;
    if (memberExpiredTime) {
      const expire = dayjs(memberExpiredTime);
      const current = dayjs().startOf('d');
      return expire.isAfter(current)
    }
    return false;
  }

  constructor() {
    super();
    this.resetLoginInfo();
  }

  resetLoginInfo = async () => {
    const {token: queryToken} = qs.parse(location.search);
    const {token: storeToken} = this.loginInfo;
    const token = queryToken || storeToken;
    if (token) {
      const loginInfo = await UserApi.getLoginInfo(token);
      this.setItem("login", loginInfo)
      await this.refresh();
    }
  }

  loginPhone = async ({phone, code}) => {
    const loginInfo = await UserApi.postLogin({
      certType: 'PHONE_VERIFY_CODE',
      tokenType: 'LONG_TIME',
      content: {
        userType: 'USER', phone, code
      }
    })
    this.setItem("login", loginInfo)
    await this.refresh();
  }

  @action
  refresh = async () => {
    if (this.isLogin && !this.isVirtual) {
      const [userinfo, wechat] = await Promise.all([
        UserApi.getCurrent(),
        WechatApi.getWechatInfo()
      ]);
      runInAction(() => {
        this.userInfo = {
          ...(userinfo || {}),
        };
        this.wechat = wechat || {}
      })
    }
  }

  @action
  logout = async () => {
    try {
      await UserApi.postLogout();
    } catch (e) {
    } finally {
      this.removeItem('login')
    }
  }

  @action
  bindLoginPhone = async ({phone, code}) => {
    if (this.loginInfo.loginType !== 'WECHAT') {
      throw new BusiError('当前登录类型必须为微信登录方可绑定登录电话')
    }
    await WechatApi.putLoginPhone(phone, code);
    await this.resetLoginInfo();
  }


  @action
  setChannel = (channel) => {
    this.channel = channel;
  }

}

export default new UserStore()