import request from "../utils/request";
import qs from 'query-string';

export const postLogin = async (data, invite) => {
  return request.post(qs.stringifyUrl({
    url: '/user/login',
    query: {invite}
  }, {skipNull: true, skipEmptyString: true}), {data})
}

export const postLogout = async () => {
  return request.post('/user/logout')
}

export const getCurrent = async () => {
  return request.get('/user/current');
}

export const getLoginInfo = async (token) => {
  return request.get(qs.stringifyUrl({
    url: '/user/login-info', query: {token}
  }, {skipNull: true, skipEmptyString: true}))
}